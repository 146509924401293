import React from 'react';
import { Table, Input, Button, Space, message } from 'antd';
import Highlighter from 'react-highlight-words';
import { SearchOutlined } from '@ant-design/icons';
import { getConditions, getCount, getDoctor, getHospital, getSpecialities, getTreatment } from '../helpers/apiCalls';
import _ from 'lodash';

class App extends React.Component {
    state = {
        searchText: '',
        searchedColumn: '',
        data: [],
        pagination: {},
    };

    getColumnSearchProps = (dataIndex) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div style={{ padding: 8 }}>
                <Input
                    ref={(node) => {
                        this.searchInput = node;
                    }}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{ marginBottom: 8, display: 'block' }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{ width: 90 }}>
                        Search
                    </Button>
                    <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                        Reset
                    </Button>
                    <Button
                        type="link"
                        size="small"
                        onClick={() => {
                            confirm({ closeDropdown: false });
                            this.setState({
                                searchText: selectedKeys[0],
                                searchedColumn: dataIndex,
                            });
                        }}>
                        Filter
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
            record[dataIndex] ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : '',
        onFilterDropdownVisibleChange: (visible) => {
            if (visible) {
                setTimeout(() => this.searchInput.select(), 100);
            }
        },
        // render: (text) =>
        //     this.state.searchedColumn === dataIndex ? (
        //         <Highlighter
        //             highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        //             searchWords={[this.state.searchText]}
        //             autoEscape
        //             textToHighlight={text ? text.toString() : ''}
        //         />
        //     ) : (
        //         text
        //     ),
    });

    fetchSpecialityCount = async (id, type) => {
        let specialityLink = `specialities=${id}`;
        const total_count = await getCount(type, specialityLink)
            .then((data) => {
                if (data) {
                    return data;
                } else return 0;
            })
            .catch((err) => {
                console.log('error', err);
                return 0;
            });
        return total_count;
    };

    fetchDoctorTreatmentCount = async (item, type) => {
        let id = item.specialities[0]?.id;
        if (id && id !== '') {
            let conditionLink = `specialities=${id}`;
            const total_count = await getCount(type, conditionLink)
                .then((data) => {
                    if (data) {
                        return data;
                    } else return 0;
                })
                .catch((err) => {
                    console.log('error', err);
                    return 0;
                });
            return total_count;
        } else return 0;
    };

    fetchDoctorConditionCount = async (item, type) => {
        let id = item.speciality?.id;
        if (id && id !== '') {
            let conditionLink = `specialities=${id}`;
            const total_count = await getCount(type, conditionLink)
                .then((data) => {
                    if (data) {
                        return data;
                    } else return 0;
                })
                .catch((err) => {
                    console.log('error', err);
                    return 0;
                });
            return total_count;
        } else return 0;
    };

    fetchConditionSuperSpeciality = (item) => {
        let superSpecialities =
            item.secondary_specialities?.length > 0
                ? item.secondary_specialities.map((speciality) => speciality.name)
                : [];
        return [...superSpecialities]?.toString();
    };

    fetchTreatmentSpeciality = (item) => {
        let specialities = item.specialities?.length > 0 ? item.specialities.map((speciality) => speciality.name) : [];
        return [...specialities]?.toString();
    };

    fetchTreatmentSuperSpeciality = (item) => {
        let superSpecialities =
            item.secondary_specialities?.length > 0
                ? item.secondary_specialities.map((speciality) => speciality.name)
                : [];
        return [...superSpecialities]?.toString();
    };

    handleSearch = (selectedKeys, confirm, dataIndex, dataIndexName) => {
        confirm();
        this.setState({
            searchText: selectedKeys[0],
            searchedColumn: dataIndex,
        });
        if (selectedKeys.length) {
            if (this.props.pageName === 'Treatments') {
                getTreatment('', '', '', selectedKeys[0], dataIndexName).then((res) => {
                    if (res?.length) {
                        message.success(`${res.length} results found`);
                        const data = res.map(async (item) => ({
                            key: item.id,
                            name: item.name,
                            keywords: item.keywords,
                            description: item.Description,
                            doctorCount: await this.fetchDoctorTreatmentCount(item, 'doctors'),
                            hospitalCount: await this.fetchDoctorTreatmentCount(item, 'hospitals'),
                            speciality: this.fetchTreatmentSpeciality(item),
                            superSpeciality: this.fetchTreatmentSuperSpeciality(item),
                        }));

                        Promise.all(data)
                            .then((values) => {
                                if (values && values.length > 0) {
                                    this.setState({
                                        ...this.state,
                                        data: values,
                                    });
                                    this.props.setTotal(values.length);
                                }
                            })
                            .catch((err) => console.log('err'));
                    } else {
                        message.error('No result found by this name');
                    }
                });
            } else if (this.props.pageName === 'hospitals') {
                let dataName = dataIndexName === 'name' ? 'Name' : dataIndexName;
                getHospital('', '', '', selectedKeys[0], dataName).then((res) => {
                    if (res?.length) {
                        message.success(`${res.length} results found`);
                        const data = res.map((item) => ({
                            key: item.id,
                            name: item.Name,
                            city: item.City,
                            slug: item.Alias,
                            address: item.Address,
                        }));
                        this.setState({
                            ...this.state,
                            data: data,
                        });
                        this.props.setTotal(data.length);
                    } else {
                        message.error('No result found by this name');
                    }
                });
            } else if (this.props.pageName === 'conditions') {
                let dataName = dataIndexName === 'keyword' ? 'keywords' : dataIndexName;
                getConditions('', '', '', selectedKeys[0], dataName).then((res) => {
                    if (res?.length) {
                        message.success(`${res.length} results found`);
                        const data = res.map(async (item) => ({
                            key: item.id,
                            name: item.name,
                            commonname: item.commonname,
                            keyword: item.keywords,
                            doctorCount: await this.fetchDoctorConditionCount(item, 'doctors'),
                            hospitalCount: await this.fetchDoctorConditionCount(item, 'hospitals'),
                            speciality: item.speciality?.name,
                            superSpeciality: this.fetchConditionSuperSpeciality(item),
                        }));

                        Promise.all(data)
                            .then((values) => {
                                if (values && values.length > 0) {
                                    this.setState({
                                        ...this.state,
                                        data: values,
                                    });
                                    this.props.setTotal(values.length);
                                }
                            })
                            .catch((err) => console.log('err'));
                    } else {
                        message.error('No result found by this name');
                    }
                });
            } else if (this.props.pageName === 'doctors') {
                let dataName =
                    dataIndexName === 'name'
                        ? 'Name'
                        : dataIndexName === 'designation'
                        ? 'Designation'
                        : dataIndexName === 'department'
                        ? 'Department'
                        : dataIndexName;

                console.log('dataName', dataName, dataIndexName);
                getDoctor('', '', '', selectedKeys[0], dataName).then((res) => {
                    if (res?.length) {
                        message.success(`${res.length} results found`);
                        const data = res.map((item) => ({
                            key: item.id,
                            name: item.Name,
                            designation: item.Designation,
                            department: item.Department,
                            slug: item.slug,
                        }));
                        this.setState({
                            ...this.state,
                            data: data,
                        });
                        this.props.setTotal(data.length);
                    } else {
                        message.error('No result found by this name');
                    }
                });
            } else if (this.props.pageName === 'specialities') {
                let dataName =
                    dataIndexName === 'keyword'
                        ? 'keywords'
                        : dataIndexName === 'description'
                        ? 'Desciption'
                        : dataIndexName;
                getSpecialities('', '', '', selectedKeys[0], dataName).then((res) => {
                    if (res?.length) {
                        message.success(`${res.length} results found`);

                        const data = res.map(async (item) => ({
                            key: item.id,
                            name: item.name,
                            keyword: item.keywords,
                            description: item.Desciption,
                            conditionCount: item.conditions && item.conditions.length,
                            doctorCount: await this.fetchSpecialityCount(item.id, 'hospitals'),
                            hospitalCount: await this.fetchSpecialityCount(item.id, 'doctors'),
                        }));
                        Promise.all(data)
                            .then((values) => {
                                if (values && values.length > 0) {
                                    this.setState({
                                        ...this.state,
                                        data: values,
                                    });
                                    this.props.setTotal(values.length);
                                }
                            })
                            .catch((err) => console.log('err'));
                    } else {
                        message.error('No result found by this name');
                    }
                });
            }
        } else {
            this.setState({ data: [] });
        }
    };

    handleReset = (clearFilters) => {
        clearFilters();
        getCount(this.props.pageName).then((res) => {
            this.props.setTotal(res);
            this.setState({
                ...this.state,
                searchText: '',
                data: [],
            });
        });
    };

    render() {
        const newColumns = this.props.columns.map((item) => ({
            ...item,
            ...this.getColumnSearchProps(`${item.dataIndex}`),
        }));
        // console.log('this.props.pagination', this.props.pagination, this.state.pagination);
        // return <Table columns={newColumns} dataSource={this.props.data} />;
        return (
            <Table
                columns={newColumns}
                dataSource={this.state.data.length > 0 ? this.state.data : this.props.data}
                onChange={this.props.handleTableChange}
                pagination={this.props.pagination}
                loading={this.props.loading}
                sortDirections={['ascend', 'descend']}
                rowSelection={{
                    type: 'radio',
                    onSelect: (record, selected, selectedRows, nativeEvent) => {
                        console.log('e is ', this.props.url + record.key);
                        let path = this.props.url + record.key;
                        // this.props.history.push(path);
                        window.location.href = path;
                    },
                }}
            />
        );
    }
}

export default App;
