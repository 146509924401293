import React from 'react';
import { Tag, Divider } from 'antd';

const Tags = ({ data, label, onClose }) => {
    console.log('Tags', data);
    return (
        <div className="w-75 my-4 mx-auto">
            <Divider orientation="left">
                {label} ({data.length})
            </Divider>
            <div>
                {data.length
                    ? data.map((item) => (
                          <Tag key={item.id} closable color="blue" onClose={() => onClose(item.id)}>
                              {item.name || item.Name}
                          </Tag>
                      ))
                    : null}
            </div>
        </div>
    );
};

export default Tags;
