import React from 'react';
import { Input, Form, InputNumber } from 'antd';

const FormItem = ({ name, label, rules, type }) => {
    return (
        <Form.Item name={name} label={label} rules={rules}>
            {!type ? <Input /> : <InputNumber />}
        </Form.Item>
    );
};

export default FormItem;
