import React from 'react';
import { Redirect } from 'react-router-dom';
import { Route } from 'react-router-dom';
import * as FeatherIcon from 'react-feather';
import { UserOutlined, SearchOutlined } from '@ant-design/icons';
import { isUserAuthenticated, getLoggedInUser } from '../helpers/authUtils';
import AddPatientProfile from '../pages/ott-upload/AddPatientProfile';
import PatientProfileEdit from '../pages/edit-forms/PatientProfileEdit';

// import Reports from '../pages/reports/ReportsForm';

// auth
const Login = React.lazy(() => import('../pages/auth/Login'));
const AddHospital = React.lazy(() => import('../pages/ott-upload/AddHospital'));
const Homepage = React.lazy(() => import('../pages/tefrontPages/Homepage'));
const Hospital = React.lazy(() => import('../pages/tefrontPages/Hospital'));
const Doctor = React.lazy(() => import('../pages/tefrontPages/Doctor'));
const Condition = React.lazy(() => import('../pages/tefrontPages/Condition'));
const Test = React.lazy(() => import('../pages/tefrontPages/Test'));
const Treatment = React.lazy(() => import('../pages/tefrontPages/Treatment'));
const TreatmentPrice = React.lazy(() => import('../pages/tefrontPages/TreatmentPrice'));
const TreatmentPriceEdit = React.lazy(() => import('../pages/edit-forms/TreatmentPrice'));
const Speciality = React.lazy(() => import('../pages/tefrontPages/Speciality'));
const SuperSpeciality = React.lazy(() => import('../pages/tefrontPages/SuperSpeciality'));
const AddDoctor = React.lazy(() => import('../pages/ott-upload/AddDoctor'));
const AddCondition = React.lazy(() => import('../pages/ott-upload/AddCondition'));
const AddSpeciality = React.lazy(() => import('../pages/ott-upload/AddSpeciality'));
const AddSuperSpeciality = React.lazy(() => import('../pages/ott-upload/AddSuperSpeciality'));
const AddTest = React.lazy(() => import('../pages/ott-upload/AddTests'));
const AddTreatment = React.lazy(() => import('../pages/ott-upload/AddTreatments'));
const AddTreatmentPrice = React.lazy(() => import('../pages/ott-upload/AddTreatmentPrice'));
const HospitalEdit = React.lazy(() => import('../pages/edit-forms/HospitalEdit'));
const TreatmentEdit = React.lazy(() => import('../pages/edit-forms/TreatmentEdit'));
const TestEdit = React.lazy(() => import('../pages/edit-forms/TestEdit'));
const ConditionEdit = React.lazy(() => import('../pages/edit-forms/ConditionEdit'));
const SuperSpecialityEdit = React.lazy(() => import('../pages/edit-forms/SuperSpeciality'));
const SpecialityEdit = React.lazy(() => import('../pages/edit-forms/Speciality'));
const DoctorEdit = React.lazy(() => import('../pages/edit-forms/DoctorEdit'));

const Logout = React.lazy(() => import('../pages/auth/Logout'));
const Register = React.lazy(() => import('../pages/auth/Register'));
const ForgetPassword = React.lazy(() => import('../pages/auth/ForgetPassword'));
const Confirm = React.lazy(() => import('../pages/auth/Confirm'));
// dashboard

// User Pages

const MyAccount = React.lazy(() => import('../pages/user/myaccount'));
const PatientProfile = React.lazy(() => import('../pages/tefrontPages/PatientProfile'));

// handle auth and authorization
const PrivateRoute = ({ component: Component, roles, ...rest }) => (
    <Route
        {...rest}
        render={(props) => {
            if (!isUserAuthenticated()) {
                // not logged in so redirect to login page with the return url
                return <Redirect to={{ pathname: '/account/login', state: { from: props.location } }} />;
            }

            const loggedInUser = getLoggedInUser();
            console.log('logedin user', loggedInUser);
            // check if route is restricted by role
            // if (roles && roles.indexOf(loggedInUser.user.type) === -1) {
            //     // role not authorised so redirect to home page
            //     return <Redirect to={{ pathname: '/' }} />;
            // }

            // authorised so return component
            return <Component {...props} />;
        }}
    />
);

// root routes
const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to="/home" />,
    route: PrivateRoute,
};

// User

const homeHomepage = {
    path: '/home',
    name: 'Homepage',
    icon: FeatherIcon.Home,
    header: 'Lauk',
    component: Homepage,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};
const hospitalRoute = {
    path: '/hospital',
    name: 'Hospitals',
    icon: FeatherIcon.FilePlus,
    component: Hospital,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};

const categoryRoutes = {
    path: '/doctor',
    name: 'Doctors',
    icon: FeatherIcon.UserPlus,
    // header: 'Navigation',
    component: Doctor,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};

const sspecialityRoutes = {
    path: '/superspeciality',
    name: ' Super Speciality',
    icon: FeatherIcon.User,
    // header: 'Navigation',
    component: SuperSpeciality,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};

const specialityRoutes = {
    path: '/speciality',
    name: 'Speciality',
    icon: FeatherIcon.User,
    // header: 'Navigation',
    component: Speciality,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};

const conditonRoutes = {
    path: '/condition',
    name: 'Conditions',
    icon: FeatherIcon.ShoppingBag,
    // header: 'Navigation',
    component: Condition,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};

const treatmentRoutes = {
    path: '/treatment',
    name: 'Treatment',
    icon: FeatherIcon.Framer,
    // header: 'Navigation',
    component: Treatment,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};
const treatmentpriceRoutes = {
    path: '/treatmentprice',
    name: 'TreatmentPrice',
    icon: FeatherIcon.Framer,
    // header: 'Navigation',
    component: TreatmentPrice,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};

const testRoutes = {
    path: '/test',
    name: 'Test',
    icon: FeatherIcon.Tag,
    // header: 'Navigation',
    component: Test,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};

const myAccountRoutes = {
    path: '/my-account',
    name: 'My Account',
    icon: FeatherIcon.User,
    // header: 'Navigation',
    component: MyAccount,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};

const patientsProfileRoutes = {
    path: '/patient-profile',
    name: 'Patients',
    icon: FeatherIcon.User,
    // header: 'Navigation',
    component: PatientProfile,
    roles: ['admin', 'user'],
    route: PrivateRoute,
};

// const meetingsRoutes = {
//     path: '/batches',
//     name: 'My Batches',
//     icon: FeatherIcon.Airplay,
//     // header: 'Navigation',
//     component: Batches,
//     roles: ['admin', 'user'],
//     route: PrivateRoute,
// };

const appRoutes = [
    homeHomepage,
    hospitalRoute,
    categoryRoutes,
    patientsProfileRoutes,
    sspecialityRoutes,
    specialityRoutes,
    conditonRoutes,
    treatmentRoutes,
    // treatmentpriceRoutes,
    testRoutes,
    myAccountRoutes,
];
// calendarAppRoutes, emailAppRoutes, projectAppRoutes, taskAppRoutes];

const addRoutes = {
    path: '/add',
    name: 'Add',
    children: [
        {
            path: '/add/hospital',
            name: 'Add Hospital',
            component: AddHospital,
            route: Route,
        },
        {
            path: '/add/doctor',
            name: 'Add Doctor',
            component: AddDoctor,
            route: Route,
        },
        {
            path: '/add/condition',
            name: 'Add Condition',
            component: AddCondition,
            route: Route,
        },
        {
            path: '/add/speciality',
            name: 'Add speciality',
            component: AddSpeciality,
            route: Route,
        },
        {
            path: '/add/patient-profile',
            name: 'Patient Profile',
            component: AddPatientProfile,
            route: Route,
        },
        {
            path: '/add/superspeciality',
            name: 'Add Superspeciality',
            component: AddSuperSpeciality,
            route: Route,
        },
        {
            path: '/add/treatment',
            name: 'Add Treatment',
            component: AddTreatment,
            route: Route,
        },
        {
            path: '/add/treatmentprice',
            name: 'Add TreatmentPrice',
            component: AddTreatmentPrice,
            route: Route,
        },
        {
            path: '/add/test',
            name: 'Add Test',
            component: AddTest,
            route: Route,
        },
    ],
};
const editRoutes = {
    path: '/edit',
    name: 'Edit',
    children: [
        {
            path: '/edit/hospital/:id',
            name: 'Hospital Edit',
            component: HospitalEdit,
            route: Route,
        },
        {
            path: '/edit/treatment/:id',
            name: 'Treatment Edit',
            component: TreatmentEdit,
            route: Route,
        },
        {
            path: '/edit/treatmentprice/:id',
            name: 'TreatmentPrice Edit',
            component: TreatmentPriceEdit,
            route: Route,
        },
        {
            path: '/edit/test/:id',
            name: 'TestEdit',
            component: TestEdit,
            route: Route,
        },
        {
            path: '/edit/superspeciality/:id',
            name: 'SuperSpeciality',
            component: SuperSpecialityEdit,
            route: Route,
        },
        {
            path: '/edit/speciality/:id',
            name: 'Speciality',
            component: SpecialityEdit,
            route: Route,
        },
        {
            path: '/edit/patient-profile/:id',
            name: 'patientProfile',
            component: PatientProfileEdit,
            route: Route,
        },
        {
            path: '/edit/doctor/:id',
            name: 'Doctor',
            component: DoctorEdit,
            route: Route,
        },
        {
            path: '/edit/condition/:id',
            name: 'Condition',
            component: ConditionEdit,
            route: Route,
        },
    ],
};
// auth
const authRoutes = {
    path: '/account',
    name: 'Auth',
    children: [
        {
            path: '/account/login',
            name: 'Login',
            component: Login,
            route: Route,
        },
        {
            path: '/account/logout',
            name: 'Logout',
            component: Logout,
            route: Route,
        },
        {
            path: '/account/register',
            name: 'Register',
            component: Register,
            route: Route,
        },
        {
            path: '/account/confirm',
            name: 'Confirm',
            component: Confirm,
            route: Route,
        },
        {
            path: '/account/forget-password',
            name: 'Forget Password',
            component: ForgetPassword,
            route: Route,
        },
    ],
};

// flatten the list of all nested routes
const flattenRoutes = (routes) => {
    try {
        let flatRoutes = [];

        routes = routes || [];
        routes.forEach((item) => {
            flatRoutes.push(item);

            if (typeof item.children !== 'undefined') {
                flatRoutes = [...flatRoutes, ...flattenRoutes(item.children)];
            }
        });
        return flatRoutes;
    } catch (err) {
        console.log(err);
    }
};

// All routes
const allRoutes = [
    rootRoute,
    // dashboardRoutes,
    ...appRoutes,
    // pagesRoutes,
    addRoutes,
    editRoutes,
    // componentsRoutes,
    // chartRoutes,

    // tableRoutes,
    authRoutes,
];

// const authProtectedRoutes = [dashboardRoutes, ...appRoutes, pagesRoutes, componentsRoutes, chartRoutes, formsRoutes, tableRoutes];
const authProtectedRoutes = [...appRoutes];
const allFlattenRoutes = flattenRoutes(allRoutes);
export { allRoutes, authProtectedRoutes, allFlattenRoutes };
