import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, message, Skeleton, Popconfirm } from 'antd';
import {
    deleteEntry,
    getConditions,
    getProfiles,
    getSpecialities,
    getSuperSpecialities,
    getTest,
    getTreatment,
    updatePatientProfile,
    updateSpeciality,
} from '../../helpers/apiCalls';
import { Card } from 'reactstrap';

import FormItem from '../../components/FormItem';
import FormItemSelect from '../../components/FormItemSelect';
import FormItemDesc from '../../components/FormItemDesc';
import Tags from '../../components/Tags';
import _ from 'lodash';
import { useHistory } from 'react-router';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const PatientProfileEdit = (props) => {
    const [form] = Form.useForm();
    const [tests, setTests] = useState([]);
    const [treatments, setTreatments] = useState([]);
    const [superSpecialities, setSuperSpecialities] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [speciality, setSpeciality] = useState('');
    const [titleList, setTitleList] = useState([]);
    const [users, setUsers] = useState([]);

    const history = useHistory();

    const { id } = props.match.params;

    React.useEffect(() => {
        getSpeciality();
    }, []);
    const onFinish = (values) => {
        // values.conditions = conditions.map((item) => item._id);

        console.log(values);
        updatePatientProfile(id, values).then((res) => message.success('updated speaciality successfully'));
        onReset();
        history.push('/patient-profile');
    };
    const getSpeciality = () => {
        getProfiles(id).then((res) => {
            const formValues = form.getFieldValue();
            formValues.fname = res.fname;
            formValues.lname = res.lname;
            formValues.email = res.email;
            formValues.phone = res.phone;
            formValues.title = res.title;

            setConditions(res.conditions);

            form.setFieldsValue({ formValues });
            console.log(res);
            setSpeciality(res);
        });
    };
    const onReset = () => {
        setConditions([]);
        form.resetFields();
    };

    const conditionSelected = (changed, value) => {
        console.log(changed, value);
        // value.Name = 'chano';
        if (_.findIndex(conditions, ['id', value.value]) !== -1) {
            return;
        }
        getConditions(value.value).then((res) => {
            let treatment = res.treatments;
            let test = res.tests;
            let superS = res.superspeciality;
            if (treatment.length) {
                setTreatments(_.uniqBy([...treatments, ...treatment], 'id'));
            }
            if (test.length) {
                setTests(_.uniqBy([...tests, test], 'id'));
            }
            if (superS) {
                setSuperSpecialities(_.uniqBy([...superSpecialities, superS], 'id'));
            }
            setConditions([...conditions, res]);
        });
    };

    const deleteMe = () => {
        deleteEntry(id, 'profiles')
            .then((res) => {
                message.success('deletion success');
                history.push('/speciality');
            })
            .catch((err) => {
                console.log(err);
                message.error('try again later');
            });
    };
    if (!speciality) {
        return <Skeleton active />;
    }
    return (
        <>
            <Row className="my-3">
                <Card className="p-2 w-100">
                    <Col span={24}>
                        <Row justify="center">
                            <Col>
                                <h2 className="text-center my-4">Edit Speaciality</h2>
                            </Col>
                            <Col offset={1}>
                                <Popconfirm className=" my-4" title="Delete this entry" onConfirm={deleteMe}>
                                    <Button type="danger">Delete</Button>
                                </Popconfirm>
                            </Col>
                        </Row>
                        <Form
                            {...layout}
                            form={form}
                            name="control-hooks"
                            onFinish={onFinish}
                            initialValues={{
                                tests: [],
                                conditions: [],
                                commonname: '',
                                treatments: [],
                                superspecialities: [],
                            }}>
                            <FormItem name="fname" label="First Name" rules={[{ required: true }]} />
                            <FormItem name="lname" label="Last Name" rules={[{ required: true }]} />
                            <FormItem name="email" label="Email Id" rules={[{ required: true }]} />
                            <FormItem name="phone" label="Phone Number" rules={[{ required: true }]} />

                            <FormItemSelect
                                name="title"
                                label="Title"
                                data={titleList}
                                onSelect={titleList}
                                // cat="Speciality"
                            />

                            <FormItemSelect
                                name="conditions"
                                label="Conditions"
                                data={conditions}
                                onSelect={conditionSelected}
                                cat="Condition"
                            />
                            <FormItemSelect name="users" label="Users" data={users} onSelect={users} cat="User" />

                            {/* <FormItemDesc /> */}
                            <Form.Item {...tailLayout}>
                                <Button className="mx-2" type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button className="mx-2" htmlType="button" onClick={onReset}>
                                    Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Card>
            </Row>
        </>
    );
};

export default PatientProfileEdit;
