import React, { useState } from 'react';
import { Form, Input, Button, Row, Col, message, Skeleton } from 'antd';
import {
    createPatientProfile,
    getConditions,
    getSpecialities,
    getSuperSpecialities,
    getTest,
    getTreatment,
    newSpeciality,
    uniqueValue,
} from '../../helpers/apiCalls';
import { Card } from 'reactstrap';
import { useHistory } from 'react-router';
import Table from '../../components/Table';
import FormItem from '../../components/FormItem';
import FormItemSelect from '../../components/FormItemSelect';
import FormItemDesc from '../../components/FormItemDesc';
import Tags from '../../components/Tags';
import _ from 'lodash';
import { useEffect } from 'react';

const layout = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    },
};
const tailLayout = {
    wrapperCol: {
        offset: 8,
        span: 16,
    },
};

const AddPatientProfile = () => {
    const [form] = Form.useForm();
    const [tests, setTests] = useState([]);
    const [treatments, setTreatments] = useState([]);
    const [superSpecialities, setSuperSpecialities] = useState([]);
    const [titleList, setTitleList] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [users, setUsers] = useState([]);
    // const [speciality, setSpeciality] = useState([]);
    const history = useHistory();

    const onFinish = (values) => {
        console.log('onFinish', values);

        values.condition = conditions;
        delete values.conditions;
        createPatientProfile('profiles', values).then((res) => message.success('patient Profile created successfully'));
        onReset();
        history.push('/patient-profile');
    };

    const onReset = () => {
        setConditions([]);

        form.resetFields();
    };
    ////////////////////////////////////////////
    const treatmentSelected = (changed, value) => {
        console.log(changed, value);
        // value.Name = 'chano';
        if (_.findIndex(treatments, ['id', value.value]) !== -1) {
            return;
        }
        getTreatment(value.value).then((res) => {
            let condition = res.conditions;

            let test = res.tests;
            let superS = res.superspeciality;
            if (condition.length) {
                setConditions(_.uniqBy([...conditions, ...condition], 'id'));
            }
            if (test.length) {
                setTests(_.uniqBy([...tests, test], 'id'));
            }
            if (superS) {
                setSuperSpecialities(_.uniqBy([...superSpecialities, superS], 'id'));
            }

            setTreatments([...treatments, res]);
        });
    };

    const conditionSelected = (changed, value) => {
        console.log(changed, value);
        // value.Name = 'chano';
        if (_.findIndex(conditions, ['id', value.value]) !== -1) {
            return;
        }
        getConditions(value.value).then((res) => {
            let treatment = res.treatments;
            let test = res.tests;
            let superS = res.superspeciality;
            if (treatment.length) {
                setTreatments(_.uniqBy([...treatments, ...treatment], 'id'));
            }
            if (test.length) {
                setTests(_.uniqBy([...tests, test], 'id'));
            }
            if (superS) {
                setSuperSpecialities(_.uniqBy([...superSpecialities, superS], 'id'));
            }
            setConditions([...conditions, res]);
        });
    };

    const testSelected = (changed, value) => {
        console.log(changed, value);
        // value.Name = 'chano';
        if (_.findIndex(tests, ['id', value.value]) !== -1) {
            return;
        }
        getTest(value.value).then((res) => {
            let condition = res.conditions;
            let treatment = res.treatments;
            let Supers = res.superspecialities;

            if (condition.length) {
                setConditions(_.uniqBy([...conditions, ...condition], 'id'));
            }
            if (treatment.length) {
                setTreatments(_.uniqBy([...treatments, ...treatment], 'id'));
            }
            if (Supers.length) {
                setSuperSpecialities(_.uniqBy([...superSpecialities, Supers], 'id'));
            }

            setTests([...tests, res]);
        });
    };

    const superSelected = (changed, value) => {
        console.log(changed, value);
        if (_.findIndex(superSpecialities, ['id', value.value]) !== -1) {
            return;
        }
        getSuperSpecialities(value.value).then((res) => {
            let treatment = res.treatments;
            let condition = res.conditions;
            let test = res.tests;
            if (treatment.length) {
                setTreatments(_.uniqBy([...treatments, ...treatment], 'id'));
            }
            if (test.length) {
                setTests(_.uniqBy([...tests, ...test], 'id'));
            }
            if (condition.length) {
                setConditions(_.uniqBy([...conditions, ...condition], 'id'));
            }

            setSuperSpecialities([...superSpecialities, res]);
        });
    };
    const conditionClosed = (id) => {
        const newData = conditions.filter((item) => item.id !== id);
        setConditions(newData);
    };
    const treatmentClosed = (id) => {
        const newData = treatments.filter((item) => item.id !== id);
        setTreatments(newData);
    };
    const testClosed = (id) => {
        const newData = tests.filter((item) => item.id !== id);
        setTests(newData);
    };
    const superClosed = (id) => {
        const newData = superSpecialities.filter((item) => item.id !== id);
        setSuperSpecialities(newData);
    };

    return (
        <>
            <Row className="my-3">
                <Card className="p-2 w-100">
                    <Col span={24}>
                        <h2 className="text-center my-4">Add Patient Profile</h2>
                        <Form
                            {...layout}
                            form={form}
                            name="control-hooks"
                            onFinish={onFinish}
                            initialValues={{
                                tests: [],
                                conditions: [],
                                commonname: '',
                                treatments: [],
                                superspecialities: [],
                            }}>
                            <FormItem name="fname" label="First Name" rules={[{ required: true }]} />
                            <FormItem name="lname" label="Last Name" rules={[{ required: true }]} />
                            <FormItem name="email" label="Email Id" rules={[{ required: true }]} />
                            <FormItem name="phone" label="Phone Number" rules={[{ required: true }]} />

                            <FormItemSelect
                                name="title"
                                label="Title"
                                data={titleList}
                                onSelect={titleList}
                                // cat="Speciality"
                            />

                            <FormItemSelect
                                name="conditions"
                                label="Conditions"
                                data={conditions}
                                onSelect={conditionSelected}
                                cat="Condition"
                            />
                            <FormItemSelect name="users" label="Users" data={users} onSelect={users} cat="User" />

                            {/* <FormItemDesc /> */}
                            <Form.Item {...tailLayout}>
                                <Button className="mx-2" type="primary" htmlType="submit">
                                    Submit
                                </Button>
                                <Button className="mx-2" htmlType="button" onClick={onReset}>
                                    Reset
                                </Button>
                            </Form.Item>
                        </Form>
                    </Col>
                </Card>
            </Row>
        </>
    );
};

export default AddPatientProfile;
