import { Form, Select } from 'antd';
import React from 'react';
import { getAllUser, getSearchParamID } from '../helpers/apiCalls';
import _ from 'lodash';
const { Option } = Select;
const FormItemSelect = ({ data, name, label, onSelect, cat, ...props }) => {
    const [option, setOption] = React.useState([]);

    const debounce = _.debounce((val) => {
        search(val);
    }, 2000);

    const search = (val) => {
        if (cat !== 'User') {
            getSearchParamID(cat, val, 10).then((res) => {
                if (label === 'Superspecialities') {
                    res = res.filter((item) => item.category === 'superSpeciality');
                }
                if (label === 'Specialities') {
                    res = res.filter((item) => item.category === 'speciality');
                }
                if (label === 'Title') {
                    res = [
                        { id: '1', value: 'Mr', label: 'Mr' },
                        { id: '2', value: 'Miss', label: 'Miss' },
                        { id: '3', value: 'Sir', label: 'Sir' },
                        { id: '4', value: 'Dr', label: 'Dr' },
                        { id: '5', value: 'Mrs', label: 'Mrs' },
                    ];
                }
                setOption(res);
            });
        } else {
            // getAllUser().then((res) => {
            //     console.log('fjfhskljfhklsfkls', res);
            // });
        }
    };

    return (
        <Form.Item name={name} label={label}>
            <Select
                filterOption={(inputValue, option) =>
                    option.props.children?.toString().toLowerCase().includes(inputValue.toLowerCase())
                }
                showSearch
                onSearch={(val) => {
                    if (val.length > 1) {
                        debounce(val);
                    }
                }}
                value={[]}
                onSelect={onSelect}
                placeholder="Select a option and change input text above"
                {...props}
                allowClear>
                {option.length
                    ? option.map((item) => (
                          <Option key={item.id} value={item.value}>
                              {item.label ? item.label : item.label}
                          </Option>
                      ))
                    : null}
            </Select>
        </Form.Item>
    );
};

export default FormItemSelect;
