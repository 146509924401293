// @flow
import appConfig from '../config';
import qs from 'qs';
import axios from 'axios';
import { getLoggedInUser } from './authUtils';
import * as rdd from 'react-device-detect';
import _ from 'lodash';

window.rdd = rdd;

window.onerror = function (error, url, line) {
    // controller.sendLog({acc:'error', data:'ERR:'+error+' URL:'+url+' L:'+line});
    console.log('Error received : ', { acc: 'error', data: 'ERR:' + error + ' URL:' + url + ' L:' + line });
};

const makeHeader = (jwt) => {
    let ujwt = jwt;
    if (!jwt) {
        let user = getLoggedInUser();
        ujwt = user.jwt;
    }
    return {
        headers: {
            Authorization: 'Bearer ' + ujwt,
        },
    };
};

const copyInvite = (userName, roomName, cid, type, password) => {
    let msg = `
${userName} is inviting you to join Lauk ${type}.

${type} Name: ${roomName}
${type} ID: ${cid}

${type} Link
${appConfig.webinarroomLink + cid}
`;

    if (password) {
        msg += `
Room Password: ${password}`;
    }

    return msg;
};

// unique ids
const uniqueValue = (form, value) => {
    if (!form.length) {
        return value;
    }

    let result = value.filter((x) => form.indexOf(x) === -1);
    result = [...result, ...form];
    return result;
};

// getsearchParamID
const getSearchParamID = async (cat, q, limit = 10) => {
    console.log(cat, q, limit);
    let query = '?q=' + q.split('(')[0] + '&cat=' + cat + '&_limit=' + limit;
    console.log(appConfig.home + '/search' + query);
    if (cat !== 'Country') {
        return await axios.get(appConfig.home + '/search' + query).then((response) => {
            // Handle success.
            // console.log(cat, ': Data Received', response.data);
            return response.data;
        });
    } else {
        let url = 'https://restcountries.com/v3.1/all?fields=name';

        try {
            return await axios.get(url).then((response) => {
                if (response.data.length > 0) {
                    return response.data.map((country, key) => ({
                        id: key,
                        value: `${country.name?.common}`,
                        label: `${country.name?.common}`,
                    }));
                }
            });
        } catch (err) {
            throw new Error(err);
        }
    }
};

// GET Count

const getCount = async (type, name = '') => {
    let url = appConfig.count + `${type}/count`;
    if (name !== '') {
        url = url.concat(`?${name}`);
    }
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const getAuthCount = async (type, name = '') => {
    const header = makeHeader();
    let url = appConfig.count + `${type}/count`;
    if (name !== '') {
        url = url.concat(`?${name}`);
    }
    try {
        return await axios.get(url, header).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
const createPatientProfile = async (type, body) => {
    const header = makeHeader();
    let url = appConfig.count + `${type}`;

    try {
        return await axios.post(url, body, header).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
const updatePatientProfile = async (ID, body) => {
    const header = makeHeader();

    try {
        return await axios.put(appConfig.profiles + '/' + ID, body, header).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const getSearchCount = async (name, type) => {
    let url = appConfig.count + `${name}/count?${type}`;
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
//BY slug

const getBySlug = async (type, slug) => {
    let url = appConfig.count + `${type}?slug=${slug}`;
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
//BY slug

const getByAlias = async (type, alias) => {
    let url = appConfig.count + `${type}?Alias=${alias}`;
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
// GET DOCTORS BY HOSPITAL ID

const getDoctorByHospital = async (id) => {
    let url = appConfig.doctors + '?hospital=' + id + '&_limit=-1';

    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (err) {
        throw new Error(err);
    }
};
const getDoctorByHospitalId = async (id) => {
    let url = appConfig.hospitals + '/' + id;

    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (err) {
        throw new Error(err);
    }
};

/**
 * Create New Hospital
 */
const newHospital = async (hospital) => {
    // console.log("saveing ", meeting);
    const header = makeHeader();
    // const user = getLoggedInUser();
    // console.log(hospital, header);
    // console.log(appConfig.hospitals);

    try {
        return await axios.post(appConfig.hospitals, hospital, header).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

const newTreatmentPrice = async (treatmentprice) => {
    const header = makeHeader();

    try {
        return await axios.post(appConfig.treatmentprice, treatmentprice, header).then((response) => {
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * Create New Doctor
 */
const newDoctor = async (doctor) => {
    // console.log("saveing ", meeting);
    const header = makeHeader();
    // const user = getLoggedInUser();
    console.log(appConfig.doctors);

    try {
        return await axios.post(appConfig.doctors, doctor, header).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * Create New conditions
 */
const newCondition = async (data) => {
    // console.log("saveing ", meeting);
    const header = makeHeader();
    // const user = getLoggedInUser();
    // console.log(appConfig.conditions);

    try {
        return await axios.post(appConfig.conditions, data, header).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * Create newSpeciality
 */
const newSpeciality = async (data) => {
    // console.log("saveing ", meeting);
    const header = makeHeader();
    // const user = getLoggedInUser();
    // console.log(appConfig.specialities);

    try {
        return await axios.post(appConfig.specialities, data, header).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * Create New newSuperSpeciality
 */
const newSuperSpeciality = async (data) => {
    // console.log("saveing ", meeting);
    const header = makeHeader();
    // const user = getLoggedInUser();
    // console.log(appConfig.superspecialities);

    try {
        return await axios.post(appConfig.superspecialities, data, header).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * Create New tests
 */
const newTest = async (data) => {
    // console.log("saveing ", meeting);
    const header = makeHeader();
    // const user = getLoggedInUser();
    // console.log(appConfig.tests);

    try {
        return await axios.post(appConfig.tests, data, header).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * Create New treatments
 */
const newTreatment = async (data) => {
    // console.log("saveing ", meeting);
    const header = makeHeader();
    // const user = getLoggedInUser();
    // console.log(appConfig.treatments);

    try {
        return await axios.post(appConfig.treatments, data, header).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * Get New Hospital
 */

const filterForHospital = (url, search, filter) => {
    // Multiple filters only for HomePage
    if (search && search.activeCities.length) {
        const query = qs.stringify(
            {
                City_contains: search.activeCities,
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        );
        url += `?${query}&_limit=-1`;

        console.log('search', url);
        return url;
    } else {
        const specialitiesIds = search.activeSpeciality.map((id) => `specialities=${id.key}`);
        const conditionsIds = search.activeConditions.map((id) => `conditions=${id.key}`);
        const treatmentsIds = search.activeTreatment.map((id) => `treatments=${id.key}`);

        const combine = [...specialitiesIds, ...conditionsIds, ...treatmentsIds];
        const queryString1 = combine.join('&');
        const queryString4 = `${queryString1}&_limit=-1`;

        url += `?${queryString4}`;

        return url;
    }
};

const getHospital = async (id = '', limit = '', start = '', search = '', filter = '', queryString) => {
    let url = appConfig.hospitals;
    if (id) {
        url += `/${id}`;
    } else {
        if (filter === 'all') {
            url = filterForHospital(url, search, filter);
        } else if (queryString) {
            url += `?${queryString}&_limit=-1`;
        } else if (filter === 'specialities' || filter === 'conditions') {
            url += `?${filter}.name=${search}`;
        } else if (search && filter) {
            filter = filter === 'city' ? 'City' : filter;
            url += `?_q=${search}&_limit=-1`;
        } else if (search) {
            url += `?_q=${search}&_limit=-1`;
        } else if (limit !== '') {
            url += `?_start=${start}&_limit=${limit}`;
        } else url += `?_limit=-1`;
    }
    console.log(url);
    try {
        return await axios.get(url).then((response) => {
            return response.data;
        });
    } catch (error) {
        // throw new Error(error);
        console.log(error);
    }
};
/**
 * Get New Test
 */
const getTest = async (id = '', limit = '', start = '', search = '') => {
    let url = appConfig.tests;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?_q=${search}`;
        } else url += `?_limit=-1`;
    }

    console.log(url);
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * Get New Doctor
 */

const filterForDoctor = (url, search, filter) => {
    // Multiple filters only for HomePage
    if (search && search.activeCities.length) {
        const query = qs.stringify(
            {
                City_contains: search.activeCities,
            },
            {
                encodeValuesOnly: true, // prettify URL
            }
        );
        url += `?${query}&_limit=-1`;

        console.log('search', url);
        return url;
    } else {
        const specialitiesIds = search.activeSpeciality.map((id) => `specialities=${id.key}`);

        const combine = [...specialitiesIds];
        const queryString1 = combine.join('&');
        const queryString4 = `${queryString1}`;

        url += `?${queryString4}&_limit=-1`;

        return url;
    }
};

const getDoctor = async (id = '', limit = '', start = '', search = '', filter = '', queryString) => {
    // console.log("saveing ", meeting);
    // const user = getLoggedInUser();
    let url = appConfig.doctors;
    if (id) {
        url += `/${id}`;
    } else {
        if (queryString) {
            // console.warn('filterVal', filterVal);
            url += `?${queryString}&_limit=-1`;
        } else if (filter === 'all') {
            url = filterForDoctor(url, search, filter);
        } else if (filter === 'Specialities') {
            const query = qs.stringify(
                {
                    Department_contains: search,
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );
            url += `?${query}`;
        } else if (filter === 'specialities' || filter === 'conditions') {
            url += `?${filter}.name=${search}&_limit=-1`;
        } else if (filter === 'hospital') {
            url += `?${filter}.Name=${search}&_limit=-1`;
        } else if (search) {
            url += `?_q=${search}&_limit=-1`;
        } else if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else url += `?_limit=-1`;
    }
    console.log(url);

    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * Get Treatment
 */
const getTreatment = async (id = '', limit = '', start = '', search = '', filter = '') => {
    if (filter === 'speciality' || filter === 'superSpeciality') {
        return;
    }
    let url = appConfig.treatments;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search instanceof Array) {
            const query = qs.stringify(
                {
                    name_contains: search,
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );
            url += `?${query}`;
            console.log('search', url);
        } else if (search) {
            url += `?_q=${search}`;
        } else url += `?_limit=-1`;
    }

    console.log(url);
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * Get TreatmentPrice
 */
const getTreatmentPrice = async (id = '', limit = '', start = '', search = '') => {
    let url = appConfig.treatmentprice;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?_q=${search}`;
        } else url += `?_limit=-1`;
    }

    console.log(url);
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * Get New
 */

const getSuperSpecialities = async (id = '', limit = '', start = '', search = '') => {
    let url = appConfig.superspecialities;
    if (id) {
        url += `/${id}`;
    } else {
        if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else if (search) {
            url += `?_q=${search}`;
        } else url += `?_limit=-1`;
    }
    console.log(url);

    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * Get  specialities
 */

const getSpecialityFilters = async (queryString) => {
    let results = [];
    if (queryString && queryString.length > 0) {
        try {
            let getDoctorSpeciality = queryString.find((str) => str.name === 'Doctors');
            let getHospitalsSpeciality = queryString.find((str) => str.name === 'Hospitals');
            let getConditionsSpeciality = queryString.find((str) => str.name === 'Conditions');

            if (getDoctorSpeciality?.value) {
                var doctor_sp = await getDoctor(getDoctorSpeciality.value, '', '', '');
                doctor_sp = doctor_sp.specialities && doctor_sp.specialities.length > 0 ? doctor_sp.specialities : [];
            } else {
                doctor_sp = [];
            }
            if (getConditionsSpeciality?.value) {
                var conditions_sp = await getConditions(getConditionsSpeciality.value, '', '', '');
                conditions_sp = (conditions_sp.speciality && [conditions_sp.speciality]) || [];
            } else {
                conditions_sp = [];
            }
            if (getHospitalsSpeciality?.value) {
                var hospital_sp = await getHospital(getHospitalsSpeciality.value, '', '', '');
                hospital_sp =
                    hospital_sp.specialities && hospital_sp.specialities.length > 0 ? hospital_sp.specialities : [];
            } else {
                hospital_sp = [];
            }
            results = [...results, ...doctor_sp, ...hospital_sp, ...conditions_sp];
            results = _.uniqBy(results, function (e) {
                return e.id;
            });
        } catch (err) {
            console.log(err);
        }
    }
    return results;
};
const getSpecialities = async (id = '', limit = '', start = '', search = '', filter = '', queryString = []) => {
    let url = appConfig.specialities;
    if (id) {
        url += `/${id}`;
    } else {
        if (search instanceof Array) {
            const query = qs.stringify(
                {
                    name_contains: search,
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );
            url += `?${query}`;
            console.log('search', url);
        } else if (queryString && queryString.length > 0 && queryString[0].name === 'Conditions') {
            filter = `conditions`;
            url += `?${filter}.id=${queryString[0].value}`;
        } else if (search) {
            // url += `?${filter}_contains=${search}`;
            url += `?_q=${search}`;
        } else if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else url += `?_limit=-1`;
    }
    console.log(url);

    //speciality for search doctors and hospitals
    if (queryString.length) {
        return getSpecialityFilters(queryString);
    }

    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        // throw new Error(error);
        console.log(error);
    }
};

const getProfiles = async (id = '', limit = '', start = '', search = '', filter = '') => {
    // console.log('searchProfile@@@@@', search, filter);
    let url = appConfig.profiles;
    const header = makeHeader();
    if (id) {
        url += `/${id}`;
    } else {
        if (search instanceof Array) {
            const query = qs.stringify(
                {
                    name_contains: search,
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );
            url += `?${query}`;
        } else if (filter === 'fname') {
            url += `?_q=${search}`;
        } else if (search === 'conditions' && filter !== '') {
            url += `?conditions=${filter}`;
        } else if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else url += `?_limit=-1`;
    }
    console.log(url);

    try {
        return await axios.get(url, header).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        // throw new Error(error);
        console.log(error);
    }
};

/**
  Profile / Patient Profiles
 */
/**
 * Get New COndition
 */
const getConditions = async (id = '', limit = '', start = '', search = '', filter = '') => {
    if (filter === 'speciality' || filter === 'superSpeciality') {
        return;
    }
    let url = appConfig.conditions;
    if (id) {
        url += `/${id}`;
    } else {
        if (search instanceof Array) {
            const query = qs.stringify(
                {
                    name_contains: search,
                },
                {
                    encodeValuesOnly: true, // prettify URL
                }
            );
            url += `?${query}`;
            console.log('search', url);
        } else if (filter === 'specialities' && search !== '') {
            url += `?speciality=${search}`;
        } else if (search) {
            url += `?_q=${search}`;
        } else if (limit) {
            url += `?_start=${start}&_limit=${limit}`;
        } else url += `?_limit=-1`;
    }
    console.log(url);
    try {
        return await axios.get(url).then((response) => {
            // console.log('New Meeting', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * Update Hospita;
 */
const updateHospital = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.hospitals + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * Update Doctor;
 */
const updateDoctor = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.doctors + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * Update TreatmentPrice;
 */
const updateTreatmentPrice = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.treatmentprice + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * Update Test;
 */
const updateTest = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.tests + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * Update condition;
 */
const updateCondition = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.conditions + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * Update speciality;
 */
const updateSpeciality = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.specialities + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * Update Super;
 */
const updateSuperSpeciality = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.superspecialities + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * Update Treatment;
 */
const updateTreatment = async (ID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.treatments + '/' + ID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};

/**
 * Update Treatment;
 */
const deleteEntry = async (ID, type) => {
    const header = makeHeader();
    let url = appConfig.delete + type + `/${ID}`;
    // const data = {password: pass};
    console.log(url);
    try {
        return await axios.delete(url, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        throw new Error(error);
    }
};
/**
 * Update User ProfileDetails
 */
const updateUserData = async (userID, data) => {
    const header = makeHeader();
    // const data = {password: pass};
    try {
        return await axios.put(appConfig.users + '/' + userID, data, header).then((response) => {
            // console.log('User Updated : ', response.data);
            return response.data;
        });
    } catch (error) {
        console.log('An error occurred:', error);
        return error;
    }
};

/**
 * Update User Password
 */
const updateUserPassword = async (userID, data) => {
    const header = makeHeader();
    // const data = {password: pass};

    return await axios
        .put(appConfig.users + '/' + userID, data, header)
        .then((response) => {
            // console.log('User Updated : ', response);
            return response.data;
        })
        .catch((error) => {
            console.log('An error occurred:', error);
            return error;
        });
};

const getAllUser = async () => {
    const header = makeHeader();
    // const data = {password: pass};

    return await axios
        .get(appConfig.users, header)
        .then((response) => {
            // console.log('User Updated : ', response);
            return response.data;
        })
        .catch((error) => {
            console.log('An error occurred:', error);
            return error;
        });
};

/*
Upload Media
*/

const newMediaUpload = async (formData, onProgress) => {
    const header = makeHeader();
    console.log(header, formData);
    try {
        return await axios
            .post(appConfig.upload, formData, {
                ...header,
                onUploadProgress: (e) => {
                    onProgress({ percent: (e.loaded / e.total) * 100 });
                },
            })
            .then((response) => {
                // console.log("MEETINGS API RECEIVED : ", response);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return [];
            });
    } catch (error) {
        console.log(error);
    }
};
/*
New Registration
*/

const newRegistration = async (data) => {
    // const header = makeHeader();
    try {
        return await axios
            .post(appConfig.registration, data)
            .then((response) => {
                // console.log("MEETINGS API RECEIVED : ", response);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return [];
            });
    } catch (error) {
        console.log(error);
    }
};
const countRegistrations = async (id) => {
    // const header = makeHeader();

    try {
        return await axios
            .get(appConfig.registration + `/count?meeting=${id}`)
            .then((response) => {
                // console.log("MEETINGS API RECEIVED : ", response);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return [];
            });
    } catch (error) {
        console.log(error);
    }
};
const getRegistrations = async (filter = '') => {
    // const header = makeHeader();
    let url = appConfig.registration;
    if (filter) {
        url = url + `?meeting=${filter}`;
    }

    try {
        return await axios
            .get(url)
            .then((response) => {
                // console.log("MEETINGS API RECEIVED : ", response);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return [];
            });
    } catch (error) {
        console.log(error);
    }
};
const getRegistrationUser = async (email, password, meeting) => {
    // const header = makeHeader();
    let url = appConfig.registration + `?meeting=${meeting}&email=${email}&password=${password}`;

    try {
        return await axios
            .get(url)
            .then((response) => {
                // console.log("MEETINGS API RECEIVED : ", response);
                return response.data;
            })
            .catch((error) => {
                console.log(error);
                return [];
            });
    } catch (error) {
        console.log(error);
    }
};

/*
All Countries
*/

const getRestCountries = async (id) => {
    let url = 'https://restcountries.com/v3.1/all?fields=name';

    try {
        return await axios.get(url).then((response) => {
            return response.data;
        });
    } catch (err) {
        throw new Error(err);
    }
};

export {
    makeHeader,
    getCount,
    getSearchCount,
    getBySlug,
    copyInvite,
    getConditions,
    getDoctor,
    getHospital,
    getSpecialities,
    getSuperSpecialities,
    getTreatment,
    getTest,
    newDoctor,
    newHospital,
    newCondition,
    newTest,
    getProfiles,
    getAuthCount,
    getAllUser,
    createPatientProfile,
    updatePatientProfile,
    newSpeciality,
    newSuperSpeciality,
    newTreatment,
    updateCondition,
    updateTreatment,
    updateTest,
    updateSuperSpeciality,
    updateSpeciality,
    updateHospital,
    updateDoctor,
    updateUserData,
    updateUserPassword,
    newMediaUpload,
    newRegistration,
    getRegistrations,
    countRegistrations,
    getRegistrationUser,
    uniqueValue,
    getSearchParamID,
    getDoctorByHospital,
    deleteEntry,
    getByAlias,
    newTreatmentPrice,
    getTreatmentPrice,
    updateTreatmentPrice,
    getDoctorByHospitalId,
    getRestCountries,
};
