import React from 'react';
import { Form, Input, Space, Button } from 'antd';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
const formItemLayoutWithOutLabel = {
    wrapperCol: {
        xs: { span: 24, offset: 0 },
        sm: { span: 20, offset: 4 },
    },
};
const FormItemDesc = () => {
    return (
        <Form.List name="description">
            {(fields, { add, remove }) => (
                <>
                    {fields.map(({ key, name, fieldKey, ...restField }) => (
                        <Space
                            size="large"
                            key={key}
                            // align="baseline"
                            direction="vertical"
                            style={{
                                display: 'flex',
                                marginBottom: 8,
                                textAlign: 'center',
                                padding: '30px',
                                border: '1px #cacaca dashed'
                            }}>
                            <Form.Item
                                {...restField}
                                name={[name, 'Title']}
                                label={'Title'}
                                fieldKey={[fieldKey, 'Title']}
                                {...formItemLayoutWithOutLabel}>
                                <Input placeholder="Title" />
                            </Form.Item>
                            <Form.Item
                                {...restField}
                                name={[name, 'youtube']}
                                label={'youtube'}
                                fieldKey={[fieldKey, 'youtube']}
                                {...formItemLayoutWithOutLabel}>
                                <Input placeholder="Youtube" />
                            </Form.Item>
                            <Form.Item
                                {...restField}
                                name={[name, 'layout']}
                                label={'layout'}
                                fieldKey={[fieldKey, 'layout']}
                                {...formItemLayoutWithOutLabel}>
                                <Input placeholder="layout" />
                            </Form.Item>
                            <Form.Item
                                {...restField}
                                name={[name, 'body']}
                                label={'body'}
                                fieldKey={[fieldKey, 'body']}
                                {...formItemLayoutWithOutLabel}>
                                <Input.TextArea placeholder="Body" />
                            </Form.Item>
                            <MinusCircleOutlined style={{ textAlign: 'center' }} onClick={() => remove(name)} />
                        </Space>
                    ))}
                    <Form.Item className="justify-content-center">
                        <Button type="dashed" onClick={() => add()} block icon={<PlusOutlined />}>
                            Add description
                        </Button>
                    </Form.Item>
                </>
            )}
        </Form.List>
    );
};

export default FormItemDesc;
